/*
* @Author: abhi
* @Date:   2023-10-21 14:14:17
* @Last Modified by:   abhi
* @Last Modified time: 2023-10-21 14:14:33
*/
<template>
  <span></span>
</template>
<script>
  import { mapGetters } from 'vuex';
  export default {
    name: 'permissionDenied',
    computed: {
    ...mapGetters({
      stream: 'inhaler/stream',
    }),
  },
  created() {
    this.onPageLoad();
  },
  methods: {
    showSnackbar() {
      return this.stream == null;
    },
    onPageLoad() {
      const snackbar = document.getElementById('snackbar');
      setTimeout(() => {
        if(this.stream === null) {
          snackbar.classList.add('show');
          snackbar.innerHTML = this.$t('common.allow_microphone_alert');
        } else {
          snackbar.classList.remove('show'); 
          snackbar.innerHTML = '';
        }
      },1000);
    }
  },
  watch: {
    stream() {
      this.onPageLoad();
    }
  }
  };
</script>
<style>
  #snackbar {
    visibility: hidden;
    min-width: 250px;
/*    margin-left: -125px;*/
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
/*    padding: 16px;*/
    padding: 8px 10px 8px 24px;
    position: fixed;
    z-index: 1;
    left: 2%;
    bottom: 8%;
  }

  /* Show the snackbar when clicking on a button (class added with JavaScript) */
  #snackbar.show {
    visibility: visible; /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
    /*-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;*/
  }

  /* Animations to fade the snackbar in and out */
  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }

  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }

  @-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }

  @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }
</style>
