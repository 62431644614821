<template>
  <section class="section2_1 text-center centerDiv">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="darkblueTxt text-center mb-1">{{ $t('instructions.video.instruction_video') }}</h1>
            <p class="lightblueTxt m-0">{{ $t('instructions.video.watch_video_request') }}</p>
          </div>
      </div>
      <div class="row"> 
          <div class="col-12">
            <div class="videoBox my-3">
              <div class="embed-responsive embed-responsive-1by1">
                <iframe width="100%" height="250" src="https://www.youtube.com/embed/D0UnqGm_miA?si=swxnK9Wo-bGoxlT1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>
              </div>
            </div>
            <router-link class="skipTxt" to="/instructions">{{ $t('instructions.video.skip') }}</router-link>
          </div>
        </div>
    </div>
    <permissionDenied />
  </section>
</template>
<script>
  import permissionDenied from '~/components/extra/permissionDenied.vue';
  export default {
    components: {
      permissionDenied,
    }
  }
</script>